export function loadZoom() {
  if (document.querySelector('#zoom-script') != null) return
  const script = window.document.createElement('script')
  script.id = 'zoom-script'
  script.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js'
  script.dataset.chatEntryId = '_so2HfD-Tz626S3-SUVRVA'
  script.dataset.env = 'us01'
  script.dataset.apikey = 'k8cIRsynRDOrRmnLkygH2A'
  script.dataset.el = '#zoom-chat'

  window.document.head.appendChild(script)
}
